<template>
  <div id="accounts">
    <Navigation />
    <v-main class="content mb-9">
      <v-container fluid>
        <v-card style="border-radius: 2px" color="#383E45">
          <v-container fluid class="py-5 px-6">
            <HeaderPage title="ข้อมูลลูกค้า" />
            <div class="wrapper-table mb-1 mt-3">
              <table class="table-table">
                <tr class="table-head">
                  <th>#</th>
                  <th>ชื่อ - นามสกุล</th>
                  <th>เบอร์โทรศัพท์</th>
                </tr>
                <tr v-if="customers.length === 0">
                  <td colspan="5">
                    <template>
                      <div
                        style="font-size: 20px"
                        class="d-flex flex-column align-center red--text my-4"
                      >
                        <v-img
                          class="mt-2 mb-4"
                          width="200"
                          src="@/assets/no-data.svg"
                          alt=""
                        >
                        </v-img>
                        ไม่พบข้อมูลลูกค้า
                      </div>
                    </template>
                  </td>
                </tr>
                <tr
                  class="table-body"
                  v-for="(data, index) in customers"
                  :key="index"
                >
                  <td style="user-select: none">{{ index + 1 }}</td>
                  <td style="user-select: none">{{ data.first_name }} {{ data.last_name }}</td>
                  <td>{{ data.phone }}</td>
                </tr>
              </table>
            </div>
          </v-container>
        </v-card>
      </v-container>
    </v-main>
    <FooterAdmin />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import FooterAdmin from "@/components/FooterAdmin";
import HeaderPage from "@/components/HeaderPage";

import CustomerService from "@/services/Customer.service";

import Swal from "sweetalert2";

export default {
  name: "Customer",
  components: {
    Navigation,
    FooterAdmin,
    HeaderPage,
  },
  data() {
    return {
      customers: [],
      admin_mmtoyshop: JSON.parse(
        localStorage.getItem(process.env.VUE_APP_AUTH)
      ),
    };
  },
  created() {
    this.getAdmins();
  },
  methods: {
    async getAdmins() {
      try {
        const response = await CustomerService.findAll();
        this.customers = response;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
  },
};
</script>

<style scoped>
</style>